'use client';
import { Disclosure } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { AiFillPhone } from 'react-icons/ai';

const navigation = [
  {
    href: '/about-us',
    name: 'About',
    submenus: [
      { href: '/about-us', name: 'About' },
      { href: '/followus', name: 'Follow Us' },
      {
        href: '/testimonials',
        name: 'Testimonials',
      },
    ],
  },
  {
    href: '/portfolio',
    name: 'Portfolio',
    submenus: [
      { href: '/portfolio', name: 'Website Design' },
      { href: '/portfolio/ecommerce', name: 'Ecommerce' },
      {
        href: '/portfolio/social-media',
        name: 'Social Media',
      },
      { href: '/portfolio/graphic-design', name: 'Graphic Design' },
    ],
  },
  {
    href: '/pricing',
    name: 'Website Packages',
    submenus: [
      {
        href: '/packages/basic-website-package',
        name: 'Basic Website Package',
      },
      {
        href: '/packages/custom-website-design-package',
        name: 'Custom Website Design Package',
      },
      {
        href: '/packages/ecommerce-website-design-package',
        name: 'Ecommerce Website Design Package',
      },
      {
        href: '/packages/landing-page-design-package',
        name: 'Landing Page Design Package',
      },
      {
        href: '/packages/shopify-store-package',
        name: 'Shopify Store Package',
      },
    ],
  },
  {
    href: '/services',
    name: 'Services',
    submenus: [
      {
        href: '/webdesign',
        name: 'Website Design Services',
        submenus: [
          {
            href: '/webdesign',
            name: 'Website Design',
          },
          {
            href: '/affordable-monthly-website-service',
            name: 'Affordable Monthly Website Service',
          },
          {
            href: '/affordable-web-design',
            name: 'Affordable Web Design',
          },
          {
            href: '/bespoke-website-design',
            name: 'Bespoke Website Design',
          },
          { href: '/business-website', name: 'Business Website' },

          { href: '/custom-blog-design', name: 'Custom Blog Design' },
          {
            href: '/custom-logo-design-services',
            name: 'Custom Logo Design Services',
          },
          {
            href: '/custom-website-design',
            name: 'Custom Website Design',
          },
          {
            href: '/custom-wordpress-website',
            name: 'Custom WordPress Website',
          },
          {
            href: '/landing-page-design-services',
            name: 'Landing Page Design Services',
          },

          {
            href: '/personal-websites-design-and-development',
            name: 'Personal Websites Design and Development',
          },
          {
            href: '/portfolio-website-design',
            name: 'Portfolio Website Design',
          },
          {
            href: '/responsive-web-design',
            name: 'Responsive Web Design',
          },
          { href: '/website-redesign', name: 'Website Redesign' },
          {
            href: '/white-label-web-design',
            name: 'White Label Web Design',
          },
        ],
      },
      {
        href: '/webdesign',
        name: 'Portal Design',
        submenus: [
          {
            href: '/community-website-design',
            name: 'Community Website Design',
          },
          { href: '/membership-site', name: 'Membership Site' },
          {
            href: '/news-portal-design-and-development',
            name: 'News Portal Design and Development',
          },
          {
            href: '/saas-website-design-agency',
            name: 'SaaS Website Design Agency',
          },
        ],
      },
      {
        href: '/website-management',
        name: 'Website Management & Maintenance',
        submenus: [
          {
            href: '/managed-website-services',
            name: 'Managed Website Services',
          },
          {
            href: '/monthly-website-subscription',
            name: 'Monthly Website Subscription',
          },
          {
            href: '/website-as-a-service',
            name: 'Website as a Service',
          },
          { href: '/website-hosting', name: 'Website Hosting' },
          { href: '/website-in-a-week', name: 'Website in a Week' },
          {
            href: '/website-maintenance',
            name: 'Website Maintenance',
          },
          {
            href: '/website-on-a-monthly-plan',
            name: 'Website on a Monthly Plan',
          },
          {
            href: '/website-performance-optimization',
            name: 'Website Performance Optimization',
          },
          { href: '/website-security', name: 'Website Security' },
        ],
      },
      {
        href: '/ux-design-services',
        name: 'UI/UX & Design',
        submenus: [
          {
            href: '/ux-design-services',
            name: 'UI/UX & Design Services',
          },
          {
            href: '/product-images-editing-service',
            name: 'Product Images Editing Service',
          },
          {
            href: '/social-media-design-services',
            name: 'Social Media Design Services',
          },
        ],
      },
      {
        href: '/freelance',
        name: 'Freelance & Local Services',
        submenus: [
          {
            href: '/freelance-graphic-designer-in-mumbai',
            name: 'Freelance Graphic Designer in Mumbai',
          },
          {
            href: '/freelance-website-developer-in-mumbai',
            name: 'Freelance Website Developer in Mumbai',
          },
          {
            href: '/website-designer-near-me',
            name: 'Website Designer Near Me',
          },
        ],
      },
      {
        href: '/web-development',
        name: 'Web Development',
        submenus: [
          {
            href: '/ai-powered-website-design-and-development',
            name: 'AI-Powered Website Design & Development',
          },
          {
            href: '/bootstrap-development-services',
            name: 'Bootstrap Development Services',
          },
          {
            href: '/custom-web-application-development',
            name: 'Custom Web Application Development',
          },
          {
            href: '/custom-web-development',
            name: 'Custom Web Development',
          },
          {
            href: '/web-developers-in-mumbai',
            name: 'Web Developers in Mumbai',
          },
          {
            href: '/tailwindcss-development-services',
            name: 'TailwindCSS Development Services',
          },
          {
            href: '/vue-js-development-services',
            name: 'Vue.js Development Services',
          },
        ],
      },
      {
        href: '/web-development',
        name: 'Laravel',
        submenus: [
          {
            href: '/aimeos-laravel-services',
            name: 'Aimeos Laravel Services',
          },
          {
            href: '/bootstrap-development-services',
            name: 'Bootstrap Development Services',
          },
          {
            href: '/custom-laravel-web-application',
            name: 'Custom Laravel Web Application',
          },
          {
            href: '/gatsbyjs-development',
            name: 'GatsbyJS Development',
          },
          { href: '/laravel', name: 'Laravel' },
          { href: '/laravel-developer', name: 'Laravel Developer' },
          {
            href: '/tailwindcss-development-services',
            name: 'TailwindCSS Development Services',
          },
          {
            href: '/vue-js-development-services',
            name: 'Vue.js Development Services',
          },
          {
            href: '/wintercms-design-services',
            name: 'WinterCMS Design Services',
          },
        ],
      },
      {
        href: '/web-development',
        name: 'Reach and Next.js',
        submenus: [
          {
            href: '/bootstrap-development-services',
            name: 'Bootstrap Development Services',
          },
          {
            href: '/gatsbyjs-development',
            name: 'GatsbyJS Development',
          },
          {
            href: '/nextjs-website-design',
            name: 'Next.js Website Design',
          },
          { href: '/react', name: 'React' },
          {
            href: '/react-development-services',
            name: 'React Development Services',
          },
          { href: '/reactnative', name: 'React Native' },
          {
            href: '/react-native-development-services',
            name: 'React Native Development Services',
          },
          {
            href: '/tailwindcss-development-services',
            name: 'TailwindCSS Development Services',
          },
        ],
      },
      {
        href: '/wordpress',
        name: 'WordPress',
        submenus: [
          { href: '/wordpress', name: 'WordPress' },
          {
            href: '/wordpress-customization',
            name: 'WordPress Customization',
          },
          {
            href: '/wordpress-plugin-development',
            name: 'WordPress Plugin Development',
          },
          { href: '/wordpress-website', name: 'WordPress Website' },
          {
            href: '/psd-to-wordpress-theme',
            name: 'PSD to WordPress Theme',
          },
        ],
      },
      {
        href: '/ecommerce',
        name: 'Ecommerce Web Design',
        submenus: [
          { href: '/ecommerce', name: 'Ecommerce' },
          {
            href: '/ecommerce-development-services',
            name: 'Ecommerce Development Services',
          },
          {
            href: '/ecommerce-management',
            name: 'Ecommerce Management',
          },
          {
            href: '/ecommerce-website-development',
            name: 'Ecommerce Website Development',
          },
          { href: '/shopify', name: 'Shopify' },
          {
            href: '/shopify-custom-development',
            name: 'Shopify Custom Development',
          },
          {
            href: '/shopify-maintenance',
            name: 'Shopify Maintenance',
          },
          {
            href: '/shopify-seo-services',
            name: 'Shopify SEO Services',
          },
          {
            href: '/shopify-theme-customization-services',
            name: 'Shopify Theme Customization Services',
          },
          {
            href: '/shopify-web-design-services',
            name: 'Shopify Web Design Services',
          },
          { href: '/shopware', name: 'Shopware' },
          {
            href: '/shopware-development-services',
            name: 'Shopware Development Services',
          },
          {
            href: '/shopware-theme-development',
            name: 'Shopware Theme Development',
          },
          { href: '/woocommerce', name: 'WooCommerce' },
        ],
      },
      {
        href: '/digital-marketing',
        name: 'SEO & Digital Marketing',
        submenus: [
          {
            href: '/analytics-and-reporting',
            name: 'Analytics & Reporting',
          },
          {
            href: '/content-marketing-services',
            name: 'Content Marketing Services',
          },
          {
            href: '/content-writing-services',
            name: 'Content Writing Services',
          },
          {
            href: '/conversion-rate-optimization-cro',
            name: 'Conversion Rate Optimization (CRO)',
          },
          { href: '/email-marketing', name: 'Email Marketing' },
          { href: '/keyword-research', name: 'Keyword Research' },
          { href: '/local-seo-services', name: 'Local SEO Services' },
          { href: '/onsite-seo', name: 'Onsite SEO' },
          {
            href: '/search-engine-optimization',
            name: 'Search Engine Optimization',
          },
          { href: '/seo-audit', name: 'SEO Audit' },
          {
            href: '/monthly-seo-packages',
            name: 'Monthly SEO Packages',
          },
          {
            href: '/social-media-marketing',
            name: 'Social Media Marketing',
          },
        ],
      },
      {
        href: '/mobile-app-development-services',
        name: 'Mobile App Development',
        submenus: [
          {
            href: '/mobile-app-screen-design-services',
            name: 'Mobile App Screen Design Services',
          },
          { href: '/mobile-solutions', name: 'Mobile Solutions' },
          { href: '/reactnative', name: 'React Native' },
          {
            href: '/react-native-development-services',
            name: 'React Native Development Services',
          },
        ],
      },
      {
        href: '/ai-automation',
        name: 'AI & Automation',
        submenus: [
          {
            href: '/ai-automation-consultancy',
            name: 'AI Automation Consultancy',
          },
        ],
      },
    ],
  },

  /* {
    href: "/industries",
    name: "Industries",
    submenus: [
      {
        href: "/industries/ecommerce-web-design-for-real-estate",
        name: "Real Estate",
      },
      { href: "/industries/biotech-website-design", name: "Biotech" },
      {
        href: "/industries/ecommerce-beauty-products",
        name: "Beauty Products",
      },
      {
        href: "/industries/financial-services-website",
        name: "Financial Services",
      },
      { href: "/industries/healthcare-website-design", name: "Healthcare" },
      {
        href: "/industries/home-services-website-design",
        name: "Home Services",
      },
      {
        href: "/industries/outdoor-website-design",
        name: "Outdoor Website",
      },
      {
        href: "/industries/restoration-website-design-services",
        name: "Restoration Services",
      },
      {
        href: "/industries/roofing-website-design",
        name: "Roofing Website Design",
      },
      {
        href: "/industries/web-design-for-family-lawyer",
        name: "Family Lawyer",
      },
      {
        href: "/industries/bankruptcy-attorney-web-design",
        name: "Bankruptcy Attorney",
      },
    ],
  }, */
  { href: '/blog', name: 'Blog' },
  { href: '/contact-us', name: 'Contact' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [openSubSubmenu, setOpenSubSubmenu] = useState(null); // Track second-level submenu

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
    setOpenSubSubmenu(null); // Close any open sub-submenu when toggling a submenu
  };

  const handleSubSubmenuToggle = (index) => {
    setOpenSubSubmenu(openSubSubmenu === index ? null : index);
  };

  let lastScrollY = 0;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!event.target.closest('.submenu')) {
      setOpenSubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="top-0 w-full text-white z-30">
      <div className="w-full bg-neutral-600 flex items-center text-center justify-center py-2 px-3 text-lg">
        Affordable and Professional Website Design Agency for Small Businesses
        and Startups
      </div>
      <Disclosure as="nav" className="bg-white  font-semibold">
        {({ open, close }) => (
          <>
            <div className="max-w-screen-full mx-0 lg:mx-16">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute2 inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 ml-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 w-64 mx-auto">
                  <Link href="/">
                    <Image
                      src="/images/logo-small.jpg"
                      alt="Prateeksha Web Design logo"
                      width={384}
                      height={72}
                      className="max-w-full h-auto"
                    />
                  </Link>
                </div>
                <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                  <div className="flex space-x-0">
                    {navigation.map((item, index) => (
                      <div key={item.name} className="relative submenu z-30">
                        {item.submenus ? (
                          <button
                            onClick={() => handleSubmenuToggle(index)}
                            className="text-black px-3 py-2 rounded-md text-lg font-medium flex items-center no-underline "
                          >
                            {item.name}
                            {item.submenus && (
                              <ChevronDownIcon
                                className={`ml-1 h-4 w-4 transition-transform ${
                                  openSubmenu === index
                                    ? 'transform rotate-180'
                                    : ''
                                }`}
                              />
                            )}
                          </button>
                        ) : (
                          <Link
                            href={item.href}
                            className="text-black px-3 py-2 rounded-md text-lg font-medium flex items-center  no-underline "
                          >
                            {item.name}
                          </Link>
                        )}
                        {openSubmenu === index && item.submenus && (
                          <div className="absolute bg-white shadow-lg rounded-md py-2 mt-1 w-72">
                            {item.submenus.map((subitem, subIndex) => (
                              <div key={subitem.name} className="relative">
                                {subitem.submenus ? (
                                  <>
                                    <button
                                      onClick={() =>
                                        handleSubSubmenuToggle(subIndex)
                                      }
                                      className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 flex items-center	 justify-start w-full text-left"
                                    >
                                      {subitem.name}
                                      <ChevronDownIcon
                                        className={`ml-2 h-4 w-4 transition-transform ${
                                          openSubSubmenu === subIndex
                                            ? 'transform rotate-180'
                                            : ''
                                        }`}
                                      />
                                    </button>
                                    {openSubSubmenu === subIndex && (
                                      <div className="absolute left-full top-0 mt-1 bg-neutral-200 shadow-lg rounded-md py-2 w-72">
                                        {subitem.submenus.map((subSubItem) => (
                                          <Link
                                            key={subSubItem.name}
                                            href={subSubItem.href}
                                            className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 no-underline "
                                          >
                                            {subSubItem.name}
                                          </Link>
                                        ))}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <Link
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block px-4 py-2 text-lg text-gray-700 hover:bg-gray-100 no-underline "
                                  >
                                    {subitem.name}
                                  </Link>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {/* Contact & Buttons */}
                <div className="flex items-center space-x-4">
                  <span className="text-gray-800">+91 (982) 121-2676</span>
                </div>

                <div className="pl-6 hidden md:block">
                  <div className="flex items-center space-x-2">
                    <Link href="/request-a-quote">
                      <button className="text-neutral-700 hover:bg-neutral-600 bg-colorOrange-500 hover:text-colorOrange-200 transition px-4 rounded-2xl text-lg font-bold py-2 pt-3">
                        Request A Quote
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <div key={item.name}>
                    {item.submenus ? (
                      <Disclosure>
                        {({ open }) => (
                          <>
                            {/* Main Menu Button */}
                            <Disclosure.Button
                              as="button"
                              className="text-black px-3 py-2 rounded-md text-lg font-medium w-full flex items-center justify-between no-underline   "
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`h-5 w-5 transition-transform ${
                                  open ? 'rotate-180' : ''
                                }`}
                              />
                            </Disclosure.Button>

                            {/* Submenu */}
                            <Disclosure.Panel className="pl-5 space-y-1">
                              {item.submenus.map((subitem) => (
                                <div key={subitem.name}>
                                  {subitem.submenus ? (
                                    <Disclosure>
                                      {({ open: subOpen }) => (
                                        <>
                                          {/* Submenu Button */}
                                          <Disclosure.Button
                                            as="button"
                                            className="text-gray-700 px-3 py-2 rounded-md text-lg font-medium w-full flex items-center justify-between"
                                          >
                                            {subitem.name}
                                            <ChevronDownIcon
                                              className={`h-5 w-5 transition-transform ${
                                                subOpen ? 'rotate-180' : ''
                                              }`}
                                            />
                                          </Disclosure.Button>

                                          {/* Sub-Submenu */}
                                          <Disclosure.Panel className="pl-5 space-y-1">
                                            {subitem.submenus.map(
                                              (subSubItem) => (
                                                <Link
                                                  key={subSubItem.name}
                                                  href={subSubItem.href}
                                                  className="block px-3 py-2 text-gray-600 hover:bg-gray-100 rounded-md no-underline "
                                                >
                                                  {subSubItem.name}
                                                </Link>
                                              )
                                            )}
                                          </Disclosure.Panel>
                                        </>
                                      )}
                                    </Disclosure>
                                  ) : (
                                    <Link
                                      href={subitem.href}
                                      className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-md no-underline "
                                    >
                                      {subitem.name}
                                    </Link>
                                  )}
                                </div>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <Link
                        href={item.href}
                        className="block text-black px-3 py-2 rounded-md text-lg font-medium no-underline "
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
